import { Dropdown } from 'components/Dropdown'
import { useOptimizationConfig } from 'features/optimizations/graphql/useOptimizationConfig'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { atom, useAtom } from 'jotai'
import { type FC, useCallback, useEffect } from 'react'

const FILTER_ID = {
  SEGMENT: 'segment',
} as const

type FilterId = (typeof FILTER_ID)[keyof typeof FILTER_ID]

type FilterStore = {
  [key in FilterId]: {
    id: FilterId
    label: string
    selected: string | undefined
  }
}

const filtersAtom = atom<FilterStore>({
  [FILTER_ID.SEGMENT]: {
    id: FILTER_ID.SEGMENT,
    label: 'Segment',
    selected: undefined,
  },
})

export const useHistoricalAnalysisFiltersAtom = () => useAtom(filtersAtom)

type FilterOptionProps = {
  filter: FilterStore[keyof FilterStore]
  options: { id: string; name: string }[]
  setFilterCallback: (id: FilterId, selected: string | undefined) => void
}

const FilterOptionDropdown: FC<FilterOptionProps> = ({
  filter: { id, selected },
  options,
  setFilterCallback,
}) => (
  <Dropdown
    callback={(value) => setFilterCallback(id, value.id)}
    value={selected}
    size="sm"
    options={options}
    matchWidth={false}
    buttonProps={{ bgColor: 'white' }}
  />
)

export const Filter = () => {
  const { data: configData } = useOptimizationConfig()
  const { segments = [] } =
    configData?.viewer?.merchant.optimizationConfig ?? {}
  const [filters, setFilters] = useHistoricalAnalysisFiltersAtom()
  const [trackEvent] = useTrackEvent()

  useEffect(() => {
    const id = FILTER_ID.SEGMENT

    if (!filters[id].selected && segments.length > 0) {
      setFilters((state) => ({
        ...state,
        [id]: {
          ...state[id],
          selected: segments[0]?.id,
        },
      }))
    }
  }, [filters, segments, setFilters])

  const setFilterCallback = useCallback(
    (id: FilterId, selected: string | undefined) => {
      trackEvent({
        eventName: 'Optimizations Historical Analysis Filters Changed',
        eventProperties: { filterId: id, selectedValues: selected },
      })
      setFilters((state) => ({
        ...state,
        [id]: {
          ...state[id],
          selected,
        },
      }))
    },
    [setFilters, trackEvent],
  )

  if (segments.length <= 1) return null

  return (
    <FilterOptionDropdown
      filter={filters[FILTER_ID.SEGMENT]}
      setFilterCallback={setFilterCallback}
      options={segments}
    />
  )
}
