import { OPTIMIZATION_METRIC } from 'features/optimizations/consts'
import { METRIC_FORMAT } from 'graphql/statistics/constants'
import type { Options } from 'highcharts'
import { colorTheme } from 'ui/theme/colors'
import { yAxisFormatter } from 'utils/chart/common'
import {
  CHART_COMPARE_COLOR,
  CHART_PRIMARY_COLOR,
  staticChartOptions,
} from 'utils/chart/constants'
import {
  type HistoricalHistoryContribution,
  type HistoricalHistoryReport,
} from '../../../hooks/useHistoricalHistoryAnalysis'
import { SERIES_MAP } from './consts'
import { getChartTooltip } from './getChartTooltip'

interface GetWaterfallChartProps {
  contributions: HistoricalHistoryContribution[]
  reports: HistoricalHistoryReport[]
  currency: string | undefined
}

const X_AXIS_ID = 'week'

export const getChart = ({
  contributions,
  reports,
  currency,
}: GetWaterfallChartProps): Options => {
  return {
    ...staticChartOptions,
    chart: {
      ...staticChartOptions.chart,
      type: 'line',
    },
    series: [
      {
        type: 'line',
        color: CHART_COMPARE_COLOR,
        xAxis: X_AXIS_ID,
        stickyTracking: true,
        data: contributions.map((contribution) => [
          new Date(contribution.date).getTime(),
          contribution.contributions.reduce((acc, c) => acc + c.ngp3, 0),
        ]),
        name: SERIES_MAP.ACTUAL,
      },
      {
        type: 'line',
        color: CHART_PRIMARY_COLOR,
        stickyTracking: true,
        xAxis: X_AXIS_ID,
        dashStyle: 'Dash',
        data: reports.map((report) => [
          new Date(report.startDate).getTime(),
          report[OPTIMIZATION_METRIC.NGP3_OPTIMAL],
        ]),
        name: SERIES_MAP.POTENTIAL,
      },
    ],
    xAxis: {
      ...staticChartOptions.xAxis,
      title: {
        text: undefined,
      },
      labels: {
        style: {
          color: colorTheme.gray[600],
          fontSize: '10px',
        },
        autoRotationLimit: 300,
      },
      type: 'datetime',
      dateTimeLabelFormats: {
        month: '%B',
      },
      visible: true,
      id: X_AXIS_ID,
      crosshair: {
        color: colorTheme.grey[200],
        dashStyle: 'Solid',
      },
    },
    yAxis: {
      title: {
        ...staticChartOptions.yAxis.title,
        margin: 16,
        style: {
          color: colorTheme.gray[700],
        },
        text: `Net gross profit 3 (${currency})`,
      },
      gridLineWidth: 0,
      labels: {
        ...staticChartOptions.yAxis.labels,
        formatter: yAxisFormatter(METRIC_FORMAT.CURRENCY),
      },
      visible: true,
    },
    tooltip: {
      ...staticChartOptions.tooltip,
      formatter: getChartTooltip({
        currency,
      }),
      shared: true,
    },
  }
}
