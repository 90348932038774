import { type DateRange } from 'constants/types'
import {
  Box,
  Divider,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  SimpleGrid,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { ButtonIcon, Button } from 'components/buttons'
import { Dropdown } from 'components/Dropdown'
import { ComboBox, SELECTION_MODE } from 'components/Dropdown/ComboBox/ComboBox'
import { Form } from 'components/Form'
import { Input } from 'components/Input'
import { Typography } from 'components/Typography'
import { addDays, format, subMonths } from 'date-fns'
import { SettingsDatePicker } from 'features/settings/views/MarketingCostsView/AddMarketingCostSidebar/SettingsDatePicker'
import { useChannelGroups } from 'graphql/useChannelGroups'
import { useChannels } from 'graphql/useChannels'
import { useCountries } from 'graphql/useCountries'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import { useUniqueDimensionValues } from 'hooks/useUniqueDimensionValues'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { defaultCurrencyOptions } from '../../shared/consts'
import { type DefaultCurrency } from '../../shared/types'
import { useCreateMarketingCostManualInput } from '../graphql/useCreateMarketingCostManualInput'
import { costTypes, dateFormat, startOfToday } from './consts'
import { validationSchema } from './schemas'
import type { ValidationSchema } from './types'

interface Props {
  isOpen: boolean
  onClose: () => void
  frontendId: string
}

const now = new Date()
const dateRange: DateRange = [subMonths(now, 1), now]

export const AddMarketingCostSidebar = ({
  isOpen,
  onClose,
  frontendId,
}: Props) => {
  const { channels, query: channelsQuery } = useChannels()
  const {
    channelGroups,
    channelsPerChannelGroups,
    query: channelGroupsQuery,
  } = useChannelGroups()
  const { countries, query: countryQuery } = useCountries()
  const { uniqueDimensionValues: campaigns, query: campaignQuery } =
    useUniqueDimensionValues({
      dateRange,
      dimensionId: 'campaign',
    })
  const { currency: initialCurrency } = useMerchantInfo()

  const [addCost] = useCreateMarketingCostManualInput()

  const methods = useForm<ValidationSchema>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      value: undefined,
      channel: undefined,
      channelGroup: undefined,
      campaign: undefined,
      country: undefined,
      costType: undefined,
      currency: initialCurrency as DefaultCurrency,
      startDate: startOfToday,
      endDate: null,
    },
  })

  const {
    getValues,
    setValue,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = methods

  const {
    campaign,
    channel,
    channelGroup,
    costType,
    country,
    currency,
    startDate,
    endDate,
  } = getValues()

  // Needs to be watched to re-render on changes for the end date to be aware and update once start date passes it
  watch('startDate')
  watch('endDate')

  const channelOptions = !channelGroup
    ? channels
    : channelsPerChannelGroups[channelGroup ?? '']

  const onSubmit: SubmitHandler<ValidationSchema> = ({
    value,
    channel,
    channelGroup,
    campaign,
    country,
    costType,
    currency,
    startDate,
    endDate,
  }) => {
    addCost({
      variables: {
        params: {
          frontendId,
          dimensions: {
            channel,
            channelGroup,
            campaign,
            country,
          },
          calculationType: costType ? costType : '',
          startDate: format(startDate, dateFormat),
          endDate: endDate ? format(endDate, dateFormat) : undefined,
          amount: String(value ?? 0),
          currency: currency,
        },
      },
    })
    close()
  }

  const close = () => {
    reset()
    onClose()
  }

  const updateDropdown = (field: keyof ValidationSchema, id: string) => {
    setValue(field, id, { shouldValidate: true })
  }

  return (
    <Drawer placement="right" isOpen={isOpen} onClose={close} size="lg">
      <DrawerOverlay />
      <DrawerContent p={6} position="relative" overflowY="auto">
        <Form<ValidationSchema> methods={methods} onSubmit={onSubmit}>
          <Flex alignItems="center" justifyContent="space-between" mb={4}>
            <Typography fontSize="xl" fontWeight="400">
              Add marketing cost
            </Typography>

            <ButtonIcon
              variant="ghost"
              name="CloseIcon"
              aria-label="Close sidebar"
              title="Close sidebar"
              onClick={onClose}
            />
          </Flex>

          <Typography fontSize="xs">
            <Typography as="span" fontWeight={700}>
              Dimensions{' '}
            </Typography>
            (Input at least 1)
          </Typography>

          <SimpleGrid mt={4} columns={2} spacing={4}>
            <ComboBox
              selectionMode={SELECTION_MODE.SINGLE}
              label="Country"
              options={countries}
              selected={country}
              error={errors.country}
              placeholder="Select country"
              isLoading={countryQuery.loading}
              isFullHeight
              setSelected={(option) => updateDropdown('country', option)}
            />

            <ComboBox
              selectionMode={SELECTION_MODE.SINGLE}
              label="Campaign"
              options={campaigns}
              selected={campaign}
              error={errors.campaign}
              placeholder="Select campaign"
              isLoading={campaignQuery.loading}
              isFullHeight
              setSelected={(option) => updateDropdown('campaign', option)}
            />

            <ComboBox
              selectionMode={SELECTION_MODE.SINGLE}
              label="Channel group"
              options={channelGroups}
              selected={channelGroup}
              error={errors.channelGroup}
              placeholder="Select channel group"
              isLoading={channelGroupsQuery.loading}
              isFullHeight
              setSelected={(option) => updateDropdown('channelGroup', option)}
            />

            <ComboBox
              selectionMode={SELECTION_MODE.SINGLE}
              label="Channel"
              options={channelOptions}
              selected={channel}
              error={errors.channel}
              placeholder="Select channel"
              isLoading={channelsQuery.loading}
              isFullHeight
              setSelected={(option) => updateDropdown('channel', option)}
            />

            <Divider my={6} gridColumn="span 2" />

            <Dropdown
              options={costTypes}
              value={costType}
              label="Cost type"
              error={errors.costType}
              placeholder="Select cost type"
              callback={(option) =>
                updateDropdown('costType', option.id.toString())
              }
            />
            <Box />

            <Input
              name="value"
              label="Value"
              type="number"
              min="0"
              error={errors.value}
            />
            <ComboBox
              selectionMode={SELECTION_MODE.SINGLE}
              label="Currency"
              options={defaultCurrencyOptions}
              selected={currency}
              error={errors.currency}
              placeholder="Select currency"
              setSelected={(option) => updateDropdown('currency', option)}
              isFullHeight
            />

            <SettingsDatePicker
              initialValue={startDate}
              updateDate={(date) => {
                setValue('startDate', date)

                // If the new start date overlaps the current end date
                // This makes sure end date cant be end up being before start date
                if (endDate && date > endDate) {
                  setValue('endDate', addDays(date, 1))
                }
              }}
              label="Start date"
              error={errors.startDate}
            />

            <SettingsDatePicker
              initialValue={endDate}
              updateDate={(date) => setValue('endDate', date)}
              isEndDate
              label="End date"
              error={errors.endDate}
              minDate={addDays(startDate, 1)}
            />
          </SimpleGrid>

          <Flex justifyContent="flex-end" mt={8}>
            <Flex justifyContent="flex-end" columnGap={2}>
              <Button variant="outline" colorScheme="gray" onClick={onClose}>
                Cancel
              </Button>
              <Button disabled={isSubmitting} type="submit" mode="dark">
                Apply
              </Button>
            </Flex>
          </Flex>
        </Form>
      </DrawerContent>
    </Drawer>
  )
}
