import {
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { captureException } from '@sentry/react'
import {
  flexRender,
  type ColumnDef,
  type HeaderGroup,
} from '@tanstack/react-table'
import { Alert } from 'components/Alert/Alert'
import { type AlertProps } from 'components/Alert/types'
import { Dropdown, type DropdownOption } from 'components/Dropdown'
import { SkeletonTableRows } from 'components/Skeleton/SkeletonTableRows'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { useFeatureFlags } from 'graphql/useFeatureFlags'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import { isEqual } from 'lodash-es'
import { useEffect, useMemo, useState } from 'react'
import { EmptyData } from 'shared/EmptyData/EmptyData'
import { BaseView } from '../BaseView'
import type { ManualInputColumns, TaxonomyType } from '../types'
import { ConfirmationModal } from './ConfirmationModal'
import { useCreateTaxonomyInput } from './graphql/useCreateTaxonomyInput'
import { useTaxonomyInputs } from './graphql/useTaxonomyInputs'
import { ManualInputTableContent } from './ManualInputTableContent'
import { countErrors } from './utils/getErrors'
import { mapTableDataToTaxonomy } from './utils/mapTableDataToTaxonomy'
import { mapTaxonomyToTableData } from './utils/mapTaxonomyToTableData'

const EmptyTable = () => (
  <Tbody>
    <Tr>
      <Td colSpan={100} border="unset">
        <EmptyData label="No data found." />
      </Td>
    </Tr>
  </Tbody>
)

export type Banner = Pick<AlertProps, 'status' | 'content'>

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<ManualInputColumns, any>[]
  title: string
  description?: string
  banner?: Banner
  types: TaxonomyType[]
}

type ManualInputTableData = Record<string, ManualInputColumns[]>
const emptyObject: ManualInputTableData = {}

export const ManualInputTable = ({
  columns,
  title,
  description,
  banner,
  types,
}: Props) => {
  const toast = useToast()
  const flags = useFeatureFlags()
  const [initialData, setInitialData] =
    useState<ManualInputTableData>(emptyObject)
  const [data, setData] = useState<ManualInputTableData>(emptyObject)
  const confirmModalDisclosure = useDisclosure()
  const { sites, currency } = useMerchantInfo()
  const [trackEvent] = useTrackEvent()
  const [createTaxonomyInput] = useCreateTaxonomyInput()
  const [headerGroups, setHeaderGroups] = useState<
    HeaderGroup<ManualInputColumns>[]
  >([])
  const siteOptions = useMemo(
    () =>
      (sites ?? []).map((site) => ({ id: site.frontendId, name: site.domain })),
    [sites],
  )
  const [selectedMerchantSite, setSelectedMerchantSite] =
    useState<DropdownOption>(siteOptions[0])
  const { taxonomyInputs, query } = useTaxonomyInputs({
    frontendId: selectedMerchantSite.id.toString(),
  })
  const selectedFrontendId = selectedMerchantSite.id.toString()
  const currentData = useMemo(
    () => data[selectedFrontendId] ?? [],
    [data, selectedFrontendId],
  )
  const showSiteDropdown = siteOptions.length > 1
  const formErrorCount = useMemo(() => countErrors(currentData), [currentData])
  const hasFormError = !!formErrorCount

  const isUpdated = useMemo(
    () => !isEqual(data, initialData),
    [data, initialData],
  )
  const isReadOnly = !flags.allowSettingsWrite
  const isSaveDisabled = !isUpdated || hasFormError || isReadOnly

  useEffect(() => {
    if (query.loading || currentData.length > 0) return

    const newData = {
      [selectedFrontendId]: types.flatMap((type) => {
        const taxonomy = taxonomyInputs.find(
          (taxonomy) => taxonomy.type === type,
        )

        return mapTaxonomyToTableData({
          taxonomy,
          type,
          columns,
          defaultCurrency: currency,
        })
      }),
    }

    setInitialData(newData)
    setData(newData)
  }, [
    columns,
    currency,
    currentData.length,
    query.loading,
    selectedFrontendId,
    taxonomyInputs,
    types,
  ])
  const onSave = async () => {
    const updatedTaxonomyTypes: TaxonomyType[] = []
    const promises = currentData.map((dataEntry) => {
      const taxonomyType = dataEntry.type as TaxonomyType

      const initialDataEntry = initialData[selectedFrontendId]?.find(
        (entry) => taxonomyType === entry.type,
      )

      const groupIsUpdated =
        taxonomyType && !isEqual(dataEntry, initialDataEntry)

      if (!groupIsUpdated) {
        return
      }
      const taxonomyInput = mapTableDataToTaxonomy({
        data: dataEntry,
        taxonomyType,
      })

      updatedTaxonomyTypes.push(taxonomyType)

      return createTaxonomyInput({
        frontendId: selectedMerchantSite.id.toString(),
        taxonomyInput,
      })
    })

    try {
      const results = await Promise.all(promises)
      const allSuccessful = results.every((result) => !result?.errors)

      if (allSuccessful) {
        setInitialData(emptyObject)
        setData(emptyObject)
        toast({
          status: 'success',
          duration: 9000,
          position: 'top-right',
          description: 'Successfully saved data',
        })
        trackEvent({
          eventName: 'Settings Taxonomy Updated',
          eventProperties: {
            taxonomyType: updatedTaxonomyTypes,
            frontendId: selectedFrontendId,
          },
        })
      }
    } catch (error) {
      toast({
        status: 'error',
        duration: 9000,
        position: 'top-right',
        description: 'Could not save data',
      })
      captureException(
        `Manual input: Error while saving taxonomy data: ${error}`,
      )
    }
  }

  return (
    <BaseView
      title={title}
      description={description}
      buttonsContent={
        showSiteDropdown ? (
          <Dropdown
            value={selectedMerchantSite?.id}
            options={siteOptions}
            callback={setSelectedMerchantSite}
            size="sm"
            variant="outline"
            matchWidth={false}
            buttonProps={{ size: 'sm', variant: 'outline' }}
          />
        ) : undefined
      }
    >
      {banner && (
        <Alert
          mb={4}
          status={banner.status}
          content={banner.content}
          closable={false}
        />
      )}
      <>
        <Table>
          <Thead>
            {headerGroups.map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th key={header.id}>
                    {!header.isPlaceholder &&
                      flexRender(
                        header.column.columnDef.header as string,
                        header.getContext(),
                      )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          {query.loading ? (
            <Tbody>
              <SkeletonTableRows rows={types.length} columns={columns.length} />
            </Tbody>
          ) : currentData.length === 0 ? (
            <EmptyTable />
          ) : (
            currentData.map((row, index) => {
              return (
                <ManualInputTableContent
                  key={index}
                  isReadOnly={isReadOnly}
                  data={[row]}
                  columns={columns}
                  setHeaderGroups={setHeaderGroups}
                  setData={(data) =>
                    setData((prev) => ({
                      ...prev,
                      [selectedFrontendId]: [
                        ...prev[selectedFrontendId].slice(0, index),
                        ...data,
                        ...prev[selectedFrontendId].slice(index + 1),
                      ],
                    }))
                  }
                />
              )
            })
          )}
        </Table>
        <Flex direction="column" alignItems="flex-end" gridGap="16px" mt={6}>
          {hasFormError && (
            <div>
              <Alert
                content={`There ${
                  formErrorCount === 1
                    ? 'is 1 error'
                    : `are ${formErrorCount} errors`
                } in the form. Please correct them before saving.`}
                status="error"
                closable={false}
              />
            </div>
          )}
          {!isReadOnly && (
            <Button
              onClick={confirmModalDisclosure.onOpen}
              variant="solid"
              size="md"
              colorScheme="primary"
              disabled={isSaveDisabled}
              isDisabled={isSaveDisabled}
            >
              Save
            </Button>
          )}
        </Flex>
        <ConfirmationModal
          isOpen={confirmModalDisclosure.isOpen}
          onClose={confirmModalDisclosure.onClose}
          onConfirmHandler={onSave}
        />
      </>
    </BaseView>
  )
}
