import { DISPLAY_FORMAT } from 'constants/displayFormats'
import { type Filter } from 'constants/types'
import {
  Flex,
  ListItem,
  OrderedList,
  Stack,
  UnorderedList,
} from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { Typography } from 'components/Typography'
import {
  useSetAnalyticsConfigAtom,
  useSetReportStateAtom,
} from 'features/reports/hooks/useReportState'
import { REPORT_PAGES } from 'features/reports/routePages'
import { type ConversationMessageFieldsFragment } from 'generated/graphql/graphql'
import { useCreateAnalyticsConfig } from 'graphql/reports/useCreateAnalyticsConfig'
import type React from 'react'
import Markdown, { type Components } from 'react-markdown'
import { useNavigate } from 'react-router-dom'
import { useCopilotIsOpen } from '../state'
import { BotIcon } from './BotIcon'

interface BotMessageProps {
  message: ConversationMessageFieldsFragment
}

export const BotMessage: React.FC<BotMessageProps> = ({ message }) => {
  const navigate = useNavigate()
  const [createAnalyticsConfig] = useCreateAnalyticsConfig({
    shouldSetUrl: false,
  })
  const setReportAtom = useSetReportStateAtom()
  const setAnalyticsConfigAtom = useSetAnalyticsConfigAtom()

  const [, setCopilotIsOpen] = useCopilotIsOpen()

  return (
    <Flex gap={4} justifyContent="start">
      <BotIcon />

      <Stack gap={4} pt={2}>
        {message.items.map((item) =>
          item.__typename === 'TextMessageItem' ? (
            <Markdown key={item.id} components={markdownComponents}>
              {item.text}
            </Markdown>
          ) : item.__typename === 'ReportMessageItem' ? (
            <Typography
              key={item.id}
              fontSize="sm"
              lineHeight={5}
              color="gray.900"
            >
              Full report:{' '}
              <Button
                onClick={async () => {
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  const { name, filters, ...rest } = item.report ?? {}

                  const analyticsConfig = await createAnalyticsConfig({
                    chart: {
                      series: [{ type: 'line', key: item.report.metrics[0] }],
                      color: 'compare',
                      xAxis: 'day',
                    },
                    filters: filters as Record<string, Filter[]>,
                    tableState: [
                      ...item.report.dimensions.map((dimension) => ({
                        id: dimension,
                        sort: null,
                        isPinned: false,
                      })),
                      ...item.report.metrics.map((metric) => ({
                        id: metric,
                        sort: null,
                        isPinned: false,
                      })),
                    ],
                    compareUnit: DISPLAY_FORMAT.PERCENTAGE_DIFF,
                    ...rest,
                    __typename: 'AnalyticsConfig',
                  })

                  if (!analyticsConfig?.id) {
                    return
                  }

                  setReportAtom(undefined)
                  setAnalyticsConfigAtom(analyticsConfig)
                  navigate(
                    REPORT_PAGES.REPORT_WITH_ANALYTICS_CONFIG(
                      String(analyticsConfig?.id),
                    ),
                  )
                  setCopilotIsOpen(false)
                }}
                size="md"
                variant="link"
                colorScheme="primary"
              >
                View report
              </Button>
            </Typography>
          ) : null,
        )}
      </Stack>
    </Flex>
  )
}

const markdownComponents: Partial<Components> = {
  p: ({ children }) => (
    <Typography fontSize="sm" lineHeight={5} color="gray.900" fontWeight={400}>
      {children}
    </Typography>
  ),
  ul: ({ children }) => <UnorderedList>{children}</UnorderedList>,
  ol: ({ children }) => <OrderedList>{children}</OrderedList>,
  li: ({ children }) => <ListItem fontSize="sm">{children}</ListItem>,
}
