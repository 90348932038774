import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { getStore } from 'shared/store'
import { filtersStateAtom, useSetFiltersState } from './useFiltersState'

export const useRemoveFilter = () => {
  const setFilters = useSetFiltersState()
  const [trackEvent] = useTrackEvent()

  return ({
    filterId,
    filterIndex,
  }: {
    filterId: string
    filterIndex: number
  }) => {
    const filters = getStore().get(filtersStateAtom)
    const currentFilter = filters[filterId] || []
    const newFilter = currentFilter.filter((_, index) => index !== filterIndex)

    const newFilters = {
      ...filters,
    }

    if (newFilter.length > 0) {
      newFilters[filterId] = newFilter
    } else {
      delete newFilters[filterId]
    }

    trackEvent({
      eventName: 'Report Filter Removed',
      eventProperties: {
        key: filterId,
        filterIndex,
      },
    })

    setFilters(newFilters)
  }
}
