import {
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  useToast,
} from '@chakra-ui/react'
import { captureException } from '@sentry/react'
import { Button, ButtonIcon } from 'components/buttons'
import { MenuOverlay } from 'components/Dropdown/components/MenuOverlay'
import { Tooltip } from 'components/Tooltip/Tooltip'
import {
  analyticsConfigAtom,
  type ReportState,
  reportStateAtom,
} from 'features/reports/atoms/reportViewStateAtom'
import { useCanEditReport } from 'features/reports/hooks/useCanEditReport'
import {
  reportIdAtom,
  reportOwnerAtom,
  reportVisibilityAtom,
} from 'features/reports/hooks/useReportState'
import { REPORT_PAGES } from 'features/reports/routePages'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { useCreateAnalyticsConfig } from 'graphql/reports/useCreateAnalyticsConfig'
import { useCreateReport } from 'graphql/reports/useCreateReport'
import { useEditReport } from 'graphql/reports/useEditReport'
import { useAtomValue } from 'jotai'
import { type FC } from 'react'
import { useNavigate } from 'react-router'
import { getStore } from 'shared/store'
import { useSelectReport } from '../ReportSidebar/hooks/useSelectReport'

type Props = {
  reportName?: string
  reportDescription?: string
  onCompleted?: () => void
}

export const SaveChangesButtons: FC<Props> = ({
  reportName,
  reportDescription,
  onCompleted,
}) => {
  const selectedReportId = useAtomValue(reportIdAtom)
  const visibility = useAtomValue(reportVisibilityAtom)
  const owner = useAtomValue(reportOwnerAtom)
  const [editReport, editReportState] = useEditReport()
  const [createReport, createReportState] = useCreateReport()
  const [trackEvent] = useTrackEvent()
  const [createAnalyticsConfig] = useCreateAnalyticsConfig({
    shouldSetUrl: false,
  })

  const toast = useToast()
  const selectReport = useSelectReport()
  const navigate = useNavigate()

  const saveReport = async () => {
    if (!selectedReportId) {
      toast({
        status: 'error',
        description: 'Could not find selected report, please try again',
      })

      return
    }
    try {
      const reportState = getStore().get(reportStateAtom)
      const analyticsConfig = getStore().get(analyticsConfigAtom)

      const newReportState: ReportState = {
        ...reportState,
        analyticsConfig,
        ...(reportName && { name: reportName }),
        ...(reportDescription && { description: reportDescription }),
      }

      await editReport({
        id: selectedReportId,
        report: newReportState,
      })

      trackEvent({
        eventName: 'Report Saved',
        eventProperties: {
          reportId: selectedReportId,
        },
      })

      selectReport(newReportState)
      toast({ status: 'success', description: 'Successfully updated report!' })
      onCompleted?.()
    } catch (error) {
      toast({
        status: 'error',
        description: 'Could not save report, please try again',
      })

      captureException(`Save report: ${error}`)
    }
  }

  const saveReportAsCopy = async () => {
    try {
      const reportState = getStore().get(reportStateAtom)
      const analyticsConfig = getStore().get(analyticsConfigAtom)
      const newAnalyticsConfig = await createAnalyticsConfig(analyticsConfig)

      const report = await createReport({
        report: {
          ...reportState,
          analyticsConfig: newAnalyticsConfig,
          description: reportDescription ?? reportState.description,
          name: `Copy of ${reportName ?? reportState.name}`,
        },
      })

      trackEvent({
        eventName: 'Report Saved As Copy',
        eventProperties: {
          reportId: selectedReportId ?? '',
          newReportId: report.id,
        },
      })
      selectReport(report)
      navigate(REPORT_PAGES.REPORT_BY_ID(report.id))
      toast({ status: 'success', description: 'Successfully created report!' })
      onCompleted?.()
    } catch (error) {
      toast({
        status: 'error',
        description: 'Could not save report as copy, please try again',
      })

      captureException(`Save report copy: ${error}`)
    }
  }

  const isLoading = editReportState.loading || createReportState.loading
  const canEditReport = useCanEditReport({ visibility, owner })
  const canSaveReport = canEditReport && selectedReportId

  return (
    <Flex>
      <Button
        size="sm"
        width="full"
        variant="solid"
        colorScheme="primary"
        isLoading={isLoading}
        onClick={canSaveReport ? saveReport : saveReportAsCopy}
      >
        {canSaveReport ? 'Save' : 'Save copy'}
      </Button>

      {canSaveReport && (
        <Menu size="md" placement="bottom-end">
          {({ isOpen }) => (
            <>
              <Tooltip label="More actions" size="sm">
                <MenuButton
                  as={ButtonIcon}
                  size="sm"
                  ml="1px"
                  disabled={isLoading}
                  aria-label="Options"
                  borderLeft="none"
                  variant="solid"
                  colorScheme="primary"
                  roundedLeft="none"
                  name={isOpen ? 'ChevronUpIcon' : 'ChevronDownIcon'}
                />
              </Tooltip>
              <Portal>
                <MenuOverlay isOpen={isOpen} />

                <MenuList>
                  <MenuItem disabled={isLoading} onClick={saveReportAsCopy}>
                    Save as copy
                  </MenuItem>
                </MenuList>
              </Portal>
            </>
          )}
        </Menu>
      )}
    </Flex>
  )
}
