import {
  COMPARE_DYNAMIC_DATE_ID,
  DYNAMIC_DATE_ID,
  type DatePreset,
  getCompareDatePreset,
  getDatePreset,
} from 'constants/getDatePresets'
import { formatOptimizationsDateRange } from 'features/optimizations/atoms/dateAtom'
import { TARGETS, type TargetType } from 'features/optimizations/consts'
import { type SetStateAction, atom, useAtom, useSetAtom } from 'jotai'

export interface PredictionStore {
  target: TargetType
  targetValue: number
  channelBounds: ChannelBound[]
}

interface ChannelBound {
  channel: string
  range: [number, number]
}

export const predictionDatePresets = [
  DYNAMIC_DATE_ID.THIS_WEEK,
  DYNAMIC_DATE_ID.NEXT_WEEK,
  DYNAMIC_DATE_ID.NEXT_4_WEEKS,
]

export const predictionCompareDatePresets = [
  DYNAMIC_DATE_ID.LAST_WEEK,
  DYNAMIC_DATE_ID.LAST_4_WEEKS,
  COMPARE_DYNAMIC_DATE_ID.PRECEDING_YEAR_MATCHING_WEEKDAYS,
]

const initialPredictionStore: PredictionStore = {
  target: TARGETS.BUDGET,
  targetValue: 0,
  channelBounds: [],
}

interface PredictionDateState {
  datePresetId: DYNAMIC_DATE_ID
  compareDatePresetId: DYNAMIC_DATE_ID | COMPARE_DYNAMIC_DATE_ID
}

const internalPredictionDateStateAtom = atom<PredictionDateState>({
  datePresetId: DYNAMIC_DATE_ID.NEXT_WEEK,
  compareDatePresetId: COMPARE_DYNAMIC_DATE_ID.PRECEDING_YEAR_MATCHING_WEEKDAYS,
})

const internalPredictionAtom = atom<PredictionStore>(initialPredictionStore)

const predictionDateStateAtom = atom(
  (get) => {
    const dateState = get(internalPredictionDateStateAtom)

    const datePreset = getDatePreset(dateState.datePresetId)
    const historicalDatePreset: DatePreset =
      getDatePreset(dateState.compareDatePresetId as DYNAMIC_DATE_ID) ||
      getCompareDatePreset(
        dateState.compareDatePresetId as COMPARE_DYNAMIC_DATE_ID,
        datePreset.value[0],
        datePreset.value[1],
      )

    return {
      ...dateState,
      datePreset,
      historicalDatePreset,
      formattedDateRange: formatOptimizationsDateRange(datePreset.value),
      formattedHistoricalDateRange: formatOptimizationsDateRange(
        historicalDatePreset.value,
      ),
    }
  },
  (_, set, newDateState: SetStateAction<PredictionDateState>) => {
    set(internalPredictionDateStateAtom, newDateState)
  },
)

export type PredictionState = ReturnType<typeof usePredictionAtom>[0]

const predictionAtom = atom(
  (get) => {
    const predictionState = get(internalPredictionAtom)
    const dateState = get(predictionDateStateAtom)

    return { ...predictionState, ...dateState }
  },
  (
    _,
    set,
    newState: SetStateAction<PredictionStore>,
    newDateState?: SetStateAction<PredictionDateState>,
  ) => {
    set(internalPredictionAtom, newState)
    if (newDateState) {
      set(internalPredictionDateStateAtom, newDateState)
    }
  },
)

export const usePredictionAtom = () => useAtom(predictionAtom)
export const usePredictionAtomSetter = () => useSetAtom(predictionAtom)

export const usePredictionDateAtom = () => useAtom(predictionDateStateAtom)
