import { type AddFilterArgsMultiSelectSearch } from 'components/Filters/AdvancedMultiSelectFilter'
import { type AddNumberFilterArgs } from 'components/Filters/NumberFilter/NumberFilter'
import { isValidFilterTuple } from 'components/Filters/NumberFilter/useNumberFilter'
import { type AddFilterArgsMultiSelect } from 'components/Filters/SimpleMultiSelectFilter'
import { getFilterNumericValue } from 'features/reports/utils/utils'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { useNormalizedMetrics } from 'graphql/statistics/useMetrics'
import { getStore } from 'shared/store'
import { filtersStateAtom, useSetFiltersState } from './useFiltersState'

const useAddFilter = () => {
  const setFilters = useSetFiltersState()
  const normalizedMetrics = useNormalizedMetrics()
  const [trackEvent] = useTrackEvent()

  return ({
    filterId,
    filterIndex,
    value,
    selectedFilterGroup,
  }:
    | AddFilterArgsMultiSelectSearch
    | AddFilterArgsMultiSelect
    | AddNumberFilterArgs) => {
    const filters = getStore().get(filtersStateAtom)
    const newFilters = { ...filters }
    const { format } = normalizedMetrics[filterId] ?? {}

    const isUpdate = Boolean(newFilters[filterId]?.[filterIndex])

    const formatNumber = (v: string | number) =>
      format
        ? String(getFilterNumericValue(v.toString(), format))
        : v.toString()

    const trackEventAddFilter = (value: string[] | string) => {
      trackEvent({
        eventName: 'Report Filter Added',
        eventProperties: {
          filterType: selectedFilterGroup,
          key: filterId,
          numberOfValues: Array.isArray(value) ? value.length : 1,
        },
      })
    }

    // Ensure newFilters[filterId] is an array
    newFilters[filterId] = [...(newFilters[filterId] || [])]

    if (isValidFilterTuple(value, selectedFilterGroup)) {
      const newValue = value.map(formatNumber)

      const newFilter = {
        comparisonId: selectedFilterGroup,
        value: newValue[0],
        maxValue: newValue[1],
      }

      if (isUpdate) {
        newFilters[filterId][filterIndex] = newFilter
      } else {
        newFilters[filterId].push(newFilter)
      }

      trackEventAddFilter([newValue[0], newValue[1]])
    } else {
      const updatedValue = Array.isArray(value)
        ? value.map(formatNumber)
        : formatNumber(value)

      const newFilter = {
        comparisonId: selectedFilterGroup,
        value: updatedValue,
      }

      if (isUpdate) {
        newFilters[filterId][filterIndex] = newFilter
      } else {
        newFilters[filterId].push(newFilter)
      }

      trackEventAddFilter(updatedValue)
    }

    setFilters(newFilters)
  }
}

export default useAddFilter
