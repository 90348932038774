import { type Filter } from 'constants/types'
import { Flex, useDisclosure } from '@chakra-ui/react'
import { ButtonIcon } from 'components/buttons'
import { useRemoveFilter } from 'features/reports/hooks/filters/useRemoveFilter'
import { useDateState } from 'features/reports/hooks/useDateState'
import { getMetricLabelWithAttribution } from 'features/reports/utils/utils'
import {
  useNormalizedAttributionModels,
  type AttributionId,
} from 'graphql/statistics/useAttributionModels'
import { useNormalizedDimensions } from 'graphql/statistics/useDimensions'
import {
  useNormalizedMetrics,
  type AttributedMetric,
} from 'graphql/statistics/useMetrics'
import { useUniqueDimensionValues } from 'hooks/useUniqueDimensionValues'
import { type FC } from 'react'
import { FilterPopupPicker } from '../FilterPopupPicker'
import { PopupButton } from './PopupButton'

interface SelectedFilterChipProps {
  filterId: string
  filter: Filter
  metricShouldShowAttribution: boolean
  filterIndex: number
}

export const SelectedFilterChip: FC<SelectedFilterChipProps> = ({
  filterId,
  filterIndex,
  filter,
  metricShouldShowAttribution,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { dateRange } = useDateState()
  const { uniqueDimensionValues, query } = useUniqueDimensionValues({
    dimensionId: filterId,
    dateRange,
  })

  const normalizedDimensions = useNormalizedDimensions()
  const normalizedMetrics = useNormalizedMetrics()
  const normalizedAttributionModels = useNormalizedAttributionModels()
  const removeFilter = useRemoveFilter()
  const metric = normalizedMetrics[filterId]

  const metricLabelWithAttribution = getMetricLabelWithAttribution(
    metric,
    normalizedAttributionModels?.[
      ((metric as AttributedMetric)?.attributionId ?? '') as AttributionId
    ]?.label,
  )

  const label =
    normalizedDimensions[filterId]?.label ||
    (metricShouldShowAttribution ? metricLabelWithAttribution : metric.label) ||
    ''

  return (
    <Flex alignItems="stretch" maxW="100%" alignContent="center">
      <FilterPopupPicker
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        options={uniqueDimensionValues}
        isLoading={query.loading}
        filterId={filterId}
        filterIndex={filterIndex}
        filterLabel={label}
        defaultSelectedFilter={filter.comparisonId}
        popupButton={
          <PopupButton
            options={uniqueDimensionValues}
            isLoading={query.loading}
            label={label}
            filter={filter}
            metric={metric}
          />
        }
      />
      <ButtonIcon
        name="CloseIcon"
        title="Remove filter"
        size="sm"
        maxH="unset"
        h="unset"
        onClick={() => {
          removeFilter({ filterId, filterIndex })
        }}
      />
    </Flex>
  )
}
