import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { Icon, type IconName } from 'components/Icon/Icon'
import { Typography } from 'components/Typography'
import { useState } from 'react'
import { teamIconColors, teamIconNames } from '../consts'

interface TeamIcon {
  name: IconName
  color: string
}
interface PickIconModalProps {
  isOpen: boolean
  onClose: () => void
  onApply: ({ name, color }: TeamIcon) => void
  currentIcon: TeamIcon
}

export const PickIconModal: React.FC<PickIconModalProps> = ({
  isOpen,
  onClose,
  onApply,
  currentIcon,
}) => {
  const [icon, setIcon] = useState(currentIcon)

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIcon(currentIcon)
        onClose()
      }}
      size="sm"
      returnFocusOnClose={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Team icon</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Typography mb={4}>
            Choose a color and an icon for the team
          </Typography>
          <Flex flexWrap="wrap" mb={6}>
            {teamIconColors.map((color) => (
              <Flex
                as="button"
                justifyContent="center"
                alignItems="center"
                flexShrink={0}
                width={8}
                height={8}
                key={color}
                aria-selected={color === icon.color}
                onClick={() => setIcon((icon) => ({ ...icon, color }))}
                _selected={{ backgroundColor: 'gray.200' }}
              >
                <Box backgroundColor={color} w={4} h={4}></Box>
              </Flex>
            ))}
          </Flex>
          <Flex flexWrap="wrap">
            {teamIconNames.map((name) => (
              <Flex
                as="button"
                justifyContent="center"
                alignItems="center"
                flexShrink={0}
                width={8}
                height={8}
                key={name}
                aria-selected={name === icon.name}
                onClick={() => setIcon((icon) => ({ ...icon, name }))}
                _selected={{ backgroundColor: 'gray.200' }}
              >
                <Icon name={name} hexColor={icon.color} size="small" />
              </Flex>
            ))}
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="gray" variant="outline" mr={2} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="solid"
            type="submit"
            colorScheme="primary"
            onClick={() => {
              onApply(icon)
              onClose()
            }}
          >
            Apply
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
