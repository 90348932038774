import { type SortDirection } from 'ag-grid-community'
import { type ExtendedSortDirection } from 'graphql/reports/types'
import { type NormalizedStatistic } from 'graphql/statistics/types'
import { type NormalizedAttributionModels } from 'graphql/statistics/useAttributionModels'
import {
  type Dimension,
  type NormalizedDimensions,
} from 'graphql/statistics/useDimensions'
import {
  getMetricKeyWithoutAttribution,
  type AttributedMetric,
  type Metric,
  type NormalizedMetrics,
} from 'graphql/statistics/useMetrics'
import { mapValues } from 'lodash-es'
import type { CsvHeader } from '../ReportHeader/DownloadCSVButton'

// Download CSV functions

const getLabelForColumn = (
  attributionModels: NormalizedAttributionModels,
  dimension: Dimension,
  metric?: Metric,
  isCompare?: boolean,
) => {
  let str = ''

  if (!metric && !isCompare) return dimension.label

  if (isCompare) {
    str += 'Compare range: '
  }

  str += metric?.label ?? ''

  if ((metric as AttributedMetric)?.attributionId) {
    str += ` - ${
      attributionModels[(metric as AttributedMetric).attributionId]?.label
    }`
  }

  return str
}

const getCsvHeaderForRow = (
  columnIds: string[],
  normalizedMetrics: NormalizedMetrics,
  normalizedDimensions: NormalizedDimensions,
  attributionModels: NormalizedAttributionModels,
  isCompare?: boolean,
) => {
  const header: CsvHeader[] = []

  for (const id of columnIds) {
    const dimension = normalizedDimensions[id]
    const metric = normalizedMetrics[id]

    header.push({
      key: id,
      label: getLabelForColumn(attributionModels, dimension, metric),
      groupKey: getMetricKeyWithoutAttribution(metric),
      isCompare,
    })

    if (isCompare && !dimension) {
      header.push({
        key: `${id}:compare`,
        label: getLabelForColumn(attributionModels, dimension, metric, true),
        groupKey: getMetricKeyWithoutAttribution(metric),
        isCompare,
      })
    }
  }

  return header
}

export const getCsvHeaders = (
  columnIds: string[],
  normalizedMetrics: NormalizedMetrics,
  normalizedDimensions: NormalizedDimensions,
  attributionModels: NormalizedAttributionModels,
  isCompare: boolean,
) => {
  const header = getCsvHeaderForRow(
    columnIds,
    normalizedMetrics,
    normalizedDimensions,
    attributionModels,
    isCompare,
  )

  return header
}

export const getCsvData = (
  data: NormalizedStatistic[],
): Record<string, number | string>[] => {
  return data.map((row) => {
    const newRow: NormalizedStatistic = {}

    for (const key in row) {
      if (row[key].comparisonValue !== undefined) {
        // Flip the comparison value into the actual value place
        newRow[`${key}:compare`] = {
          ...row[key],
          value: row[key].comparisonValue ?? '',
          formattedValue: row[key].comparisonFormattedValue ?? '',
        }
      }
      newRow[key] = row[key]
    }

    return mapValues(newRow, (statistic) => {
      return typeof statistic.value === 'string'
        ? statistic.formattedValue
        : statistic.value
    })
  })
}

export const mapSortToAGSort = (sort: ExtendedSortDirection): SortDirection => {
  return sort === 'ascCompare' ? 'asc' : sort === 'descCompare' ? 'desc' : sort
}
