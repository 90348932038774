import { type DateRange } from 'constants/types'
import { addHours, endOfWeek, addDays, endOfMonth, endOfYear } from 'date-fns'
import { type DateState } from 'features/reports/hooks/useDateState'
import { type CompareUnit, type ChartSerie } from 'graphql/reports/types'
import type { TimeUnit, NormalizedStatistic } from 'graphql/statistics/types'
import { type NormalizedMetrics } from 'graphql/statistics/useMetrics'
import { type TooltipFormatterContextObject } from 'highcharts'
import { renderToString } from 'react-dom/server'
import { colorTheme, getColor } from 'ui/theme/colors'
import { calcDifference } from 'utils/calcDifference'
import { formatDateTime } from 'utils/chart/common'
import {
  compareColor,
  hourDimension,
  dayDimension,
  weekDimension,
  monthDimension,
  yearDimension,
  isTimeUnit,
} from 'utils/chart/constants'
import { formatMetric } from 'utils/numberFormats'
import { parseDate } from 'utils/parseDate'
import { type ChartMetadata } from './types'

type ChartTooltipProps = TooltipFormatterProps & {
  context: TooltipFormatterContextObject
}

const getFormattedDateTime = (
  start: number | Date | null,
  end: number | Date | null,
  isPerHour: boolean,
) => {
  if (!start || !end) return ''

  return formatDateTime({ start, end, isPerHour })
}

const getDynamicEndDate = (date: number | Date, xAxis: TimeUnit | string) => {
  switch (xAxis) {
    case hourDimension.id:
      return addHours(date, 1)
    case dayDimension.id:
      return date
    case weekDimension.id:
      return addDays(endOfWeek(date), 1)
    case monthDimension.id:
      return endOfMonth(date)
    case yearDimension.id:
      return endOfYear(date)
    default:
      return date
  }
}

const getDateRange = (
  value: string | number | undefined,
  dateRange: DateRange,
  timezone: string,
  xAxis: string,
) => {
  const parseStartDate =
    value && dateRange[0]
      ? Math.max(parseDate(value, timezone), dateRange[0].getTime())
      : dateRange[0]
  const dynamicEndDate = parseStartDate
    ? new Date(getDynamicEndDate(parseStartDate, xAxis)).getTime()
    : undefined
  const parseEndDate =
    value && dynamicEndDate && dateRange[1]
      ? Math.min(dynamicEndDate, dateRange[1].getTime())
      : dateRange[1]

  return [parseStartDate, parseEndDate] as const
}

const ChartTooltip = ({
  xAxis,
  context,
  dateState,
  series,
  normalizedMetrics,
  currency,
  groupBy,
  data,
  timezone,
  compareUnit,
}: ChartTooltipProps) => {
  const uniqueSeries = series.filter(
    (currentSerie, index, self) =>
      index === self.findIndex((serie) => serie.key === currentSerie.key),
  )

  const isXAxisTimeUnit = isTimeUnit(xAxis)
  const isPerHour = isXAxisTimeUnit && xAxis === hourDimension.id
  const title = !isXAxisTimeUnit ? context.key : ''
  const { dateRange, compareDateRange, isCompare } = dateState

  const timeStamp = isXAxisTimeUnit ? context.key : undefined
  const dataPoint = data.find(
    (point) =>
      new Date(point[xAxis].value).getTime().toString() ===
      timeStamp?.toString(),
  )

  const { value: pointActualDate, comparisonValue: pointCompareDate } =
    (isXAxisTimeUnit && dataPoint ? dataPoint[xAxis] : undefined) ?? {}

  // If xAxis is a time unit, we use the value from the point, otherwise we show the date range
  const [start, end] = getDateRange(pointActualDate, dateRange, timezone, xAxis)

  const [compareStart, compareEnd] = getDateRange(
    pointCompareDate,
    compareDateRange,
    timezone,
    xAxis,
  )
  const formattedDateTime = getFormattedDateTime(start, end, isPerHour)
  const formattedCompareDateTime = getFormattedDateTime(
    compareStart,
    compareEnd,
    isPerHour,
  )

  const showCompare = isCompare && groupBy === compareColor.id
  const showGroupName = groupBy && groupBy !== compareColor.id

  return (
    <div>
      <ChartTooltipTitle title={title} />
      <ChartTooltipSectionLabel label={formattedDateTime} />
      {uniqueSeries.flatMap((serie) => {
        // The following metadata typing should be improved to avoid the redundancy
        const metricPoints = (context?.points ?? []).filter(
          (point) =>
            'metadata' in point.series.options &&
            (point.series.options.metadata as ChartMetadata).metric.key ===
              serie.key,
        )
        const points = metricPoints.filter(
          (point) =>
            'metadata' in point.series.options &&
            !(point.series.options.metadata as ChartMetadata).isCompareSeries,
        )
        const comparePoint = showCompare
          ? metricPoints.find(
              (point) =>
                'metadata' in point.series.options &&
                (point.series.options.metadata as ChartMetadata)
                  .isCompareSeries,
            )
          : undefined
        const { label, format, isReversePositive } =
          normalizedMetrics[serie.key] ?? {}

        // We only show points with a value when having groups. Otherwise, we could get overwhelming tooltips. https://linear.app/demaai/issue/APP-288/tooltip-should-not-show-all-na-values
        const pointsWithData = showGroupName
          ? points.filter((point) => point?.y)
          : points

        if (pointsWithData.length === 0) {
          return <div>No data</div>
        }

        return pointsWithData.map((point) => {
          const actualValue = point?.y

          const { formattedDifference, color: diffColor } = calcDifference({
            value: actualValue ?? 0,
            compareValue:
              showCompare && comparePoint?.y ? comparePoint?.y : undefined,
            format: format ?? 'currency',
            isOppositeDiffColor: !!isReversePositive,
            displayFormat: compareUnit,
            isAbbreviated: true,
          })

          const formattedValue = actualValue
            ? formatMetric(format, actualValue, currency)
            : 'N/A'

          const showDiffValue = showCompare && actualValue && comparePoint?.y

          return (
            <ChartTooltipMetricRow
              iconColor={point?.color?.toString() ?? '#000'}
              metricName={showGroupName ? (point?.series.name ?? label) : label}
              value={formattedValue}
              key={serie.key}
              diffColor={getColor(diffColor)}
              diffValue={showDiffValue ? formattedDifference : undefined}
            />
          )
        })
      })}
      {showCompare ? (
        <div
          style={{
            marginTop: 16,
          }}
        >
          <ChartTooltipSectionLabel label={formattedCompareDateTime} />
          {uniqueSeries.flatMap((serie) => {
            const comparePoints = (context?.points ?? []).filter((point) => {
              if ('metadata' in point.series.options) {
                const { metric, isCompareSeries } =
                  (point.series.options.metadata as ChartMetadata) || {}

                return metric.key === serie.key && isCompareSeries
              }

              return false
            })

            return comparePoints.map((comparePoint) => {
              const { label, format } = normalizedMetrics[serie.key] ?? {}
              const compareValue = comparePoint?.y

              const formattedCompareValue = compareValue
                ? formatMetric(format, compareValue, currency)
                : 'N/A'

              return (
                <ChartTooltipMetricRow
                  iconColor={comparePoint?.color?.toString()}
                  metricName={label}
                  value={formattedCompareValue}
                  valueColor={colorTheme.grey[600]}
                  key={serie.key}
                />
              )
            })
          })}
        </div>
      ) : null}
    </div>
  )
}

const ChartTooltipTitle = ({ title }: { title: string | undefined }) => {
  if (!title) return null

  return (
    <div
      style={{
        color: colorTheme.grey[700],
        fontWeight: 400,
        marginBottom: 16,
        fontSize: '12px',
        lineHeight: '16px',
      }}
    >
      {title}
    </div>
  )
}

const ChartTooltipSectionLabel = ({ label }: { label: string | undefined }) => {
  if (!label) return null

  return (
    <div
      style={{
        marginBottom: '4px',
        color: colorTheme.grey[700],
        fontWeight: 600,
        fontSize: '10px',
        lineHeight: '14px',
      }}
    >
      {label}
    </div>
  )
}

interface ChartTooltipMetricRowProps {
  metricName: string
  iconColor?: string
  value: string
  valueColor?: string
  diffValue?: string
  diffColor?: string
}

const ChartTooltipMetricRow = ({
  metricName,
  iconColor,
  value,
  valueColor,
  diffValue,
  diffColor,
}: ChartTooltipMetricRowProps) => {
  return (
    <div
      style={{
        fontSize: '12px',
        lineHeight: '16px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {iconColor ? (
        <div
          style={{
            width: '8px',
            height: '8px',
            borderRadius: '9999px',
            marginRight: 4,
            background: iconColor,
          }}
        ></div>
      ) : null}
      <span
        style={{
          color: colorTheme.grey[900],
          fontWeight: 400,
          marginRight: 4,
        }}
      >
        {`${metricName}:`}
      </span>
      <span
        style={{
          color: valueColor ?? colorTheme.grey[900],
          fontWeight: 600,
          marginRight: 8,
        }}
      >
        {value}
      </span>
      {diffValue ? (
        <span
          style={{
            color: diffColor,
            fontWeight: 600,
          }}
        >
          {diffValue}
        </span>
      ) : null}
    </div>
  )
}

interface TooltipFormatterProps {
  series: ChartSerie[]
  xAxis: string
  dateState: DateState
  normalizedMetrics: NormalizedMetrics
  currency: string | undefined
  groupBy: string | null
  data: NormalizedStatistic[]
  timezone: string
  compareUnit: CompareUnit
}

export const getTooltipFormatter = (props: TooltipFormatterProps) =>
  function (this: Highcharts.TooltipFormatterContextObject) {
    const element = <ChartTooltip {...props} context={this} />

    // Used as highcharts requires a string and not an Element
    return renderToString(element)
  }
