import { type MetricFormat } from 'graphql/statistics/types'
import { useNormalizedMetrics } from 'graphql/statistics/useMetrics'
import { useEffect, useState } from 'react'
import {
  FilterOperator,
  type FilterTuple,
  type FilterTupleWithNull,
} from '../types'
import { useSelectedFilterGroup } from '../useSelectedFilterGroup'
import { type NumberFilterProps } from './NumberFilter'

type UseNumberFilterArgs = Pick<
  NumberFilterProps,
  | 'defaultSelectedFilter'
  | 'filterId'
  | 'addFilter'
  | 'removeFilter'
  | 'values'
  | 'filterIndex'
>

const getFormattedValue = (
  value: number | null,
  format: MetricFormat = 'integer',
) => {
  if (value === null) return value

  return format.toLowerCase().includes('percent') ? value * 100 : value
}

const isNumber = (value: unknown): value is number => {
  return (
    value !== undefined &&
    value !== null &&
    !isNaN(value as number) && // the as number is just used to make TS happy here
    typeof value === 'number'
  )
}

const isValidNumber = (
  value: unknown,
  filterOperator: FilterOperator,
): value is number => {
  return (
    [FilterOperator.greaterThan, FilterOperator.lessThan].includes(
      filterOperator,
    ) && isNumber(value)
  )
}

const isValidFilterTupleWithNull = (
  value: unknown,
  filterOperator: FilterOperator,
): value is FilterTupleWithNull => {
  return (
    filterOperator === FilterOperator.between &&
    Array.isArray(value) &&
    value.length === 2
  )
}

export const isValidFilterTuple = (
  value: unknown,
  filterOperator: FilterOperator,
): value is FilterTuple => {
  return (
    isValidFilterTupleWithNull(value, filterOperator) && value.every(isNumber)
  )
}

export const useNumberFilter = ({
  values,
  defaultSelectedFilter,
  filterId,
  filterIndex,
  addFilter,
  removeFilter,
}: UseNumberFilterArgs) => {
  const normalizedMetrics = useNormalizedMetrics()
  const { selectedFilterGroup, setSelectedFilterGroup } =
    useSelectedFilterGroup({
      filterId,
      defaultSelectedFilter,
      fallback: FilterOperator.greaterThan,
    })
  const showIsBetweenSection = selectedFilterGroup === FilterOperator.between
  const format = normalizedMetrics[filterId].format

  const [draftValuesFilterTuple, setDraftValuesFilterTuple] =
    useState<FilterTupleWithNull>([null, null])

  const [draftValueNumber, setDraftValueNumber] = useState<number | null>(null)

  // Needed as filter can be updated in multiple locations so that they are in sync
  useEffect(() => {
    const formattedValue = Array.isArray(values)
      ? values.map((val) => getFormattedValue(Number(val), format))
      : getFormattedValue(Number(values), format)

    setDraftValuesFilterTuple(
      isValidFilterTupleWithNull(formattedValue, selectedFilterGroup)
        ? formattedValue
        : ([null, null] as FilterTupleWithNull),
    )

    setDraftValueNumber(
      isValidNumber(formattedValue, selectedFilterGroup)
        ? formattedValue
        : null,
    )
  }, [format, selectedFilterGroup, values])

  const updateNumberFilter = () => {
    const validValue = isValidFilterTuple(
      draftValuesFilterTuple,
      selectedFilterGroup,
    )
      ? draftValuesFilterTuple
      : isValidNumber(draftValueNumber, selectedFilterGroup)
        ? draftValueNumber.toString()
        : undefined

    // the user deselected all options, so we need to remove this filter
    if (!validValue) {
      removeFilter?.({ filterId, filterIndex })

      return
    }

    addFilter?.({
      filterId,
      filterIndex,
      value: validValue,
      selectedFilterGroup,
    })
  }

  return {
    draftValuesFilterTuple,
    draftValueNumber,
    showIsBetweenSection,
    selectedFilterGroup,
    setSelectedFilterGroup,
    updateNumberFilter,
    setDraftValuesFilterTuple,
    setDraftValueNumber,
  }
}
