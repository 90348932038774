import { Box, Flex } from '@chakra-ui/react'
import { useRef, type FC } from 'react'
import { FilterGroup } from '../shared/FilterGroup/FilterGroup'
import { FilterPopover } from '../shared/FilterPopover'
import { FreeTextInputField } from '../shared/FreeTextInputField'
import { GroupHeader } from '../shared/GroupHeader'
import {
  FilterOperator,
  type BaseFilterProps,
  type AddFilterArgsBase,
  type FilterTuple,
} from '../types'
import { BetweenFilterInputField } from './BetweenFilterInputField'
import { useNumberFilter } from './useNumberFilter'

export type AddNumberFilterArgs = AddFilterArgsBase & {
  value: string | FilterTuple
}

export type NumberFilterProps = Omit<
  BaseFilterProps,
  'options' | 'selectedOptions' | 'isLoading'
> & {
  values: string[] | string
  addFilter?: (filter: AddNumberFilterArgs) => void
}

export const NumberFilter: FC<NumberFilterProps> = ({
  filterId,
  filterIndex,
  filterLabel,
  defaultSelectedFilter,
  isOpen,
  onOpen,
  onClose,
  isOpenByDefault = false,
  values,
  addFilter,
  removeFilter,
  ...rest
}) => {
  const {
    showIsBetweenSection,
    selectedFilterGroup,
    draftValuesFilterTuple,
    draftValueNumber,
    setSelectedFilterGroup,
    updateNumberFilter,
    setDraftValueNumber,
    setDraftValuesFilterTuple,
  } = useNumberFilter({
    values,
    defaultSelectedFilter,
    filterId,
    filterIndex,
    addFilter,
    removeFilter,
  })

  const focusedElementRef = useRef<HTMLInputElement>(null)
  const closeCallback = () => {
    onClose?.()
    updateNumberFilter()
  }

  return (
    <FilterPopover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={closeCallback}
      isOpenByDefault={isOpenByDefault}
      focusedElementRef={focusedElementRef}
      {...rest}
    >
      <Flex flexDir="column">
        {filterLabel && <GroupHeader label={filterLabel} />}
        <FilterGroup
          options={[
            FilterOperator.greaterThan,
            FilterOperator.lessThan,
            FilterOperator.between,
          ]}
          defaultValue={selectedFilterGroup}
          setSelectedFilter={setSelectedFilterGroup}
        />
        <Box py={2} px={3} minH={20}>
          {showIsBetweenSection ? (
            <BetweenFilterInputField
              defaultValue={draftValuesFilterTuple}
              onChange={setDraftValuesFilterTuple}
              onSubmit={closeCallback}
            />
          ) : (
            <FreeTextInputField
              type="number"
              value={draftValueNumber ?? null}
              onChange={(val) =>
                setDraftValueNumber(
                  val && !isNaN(Number(val)) ? Number(val) : null,
                )
              }
              onSubmit={closeCallback}
              ref={focusedElementRef}
            />
          )}
        </Box>
      </Flex>
    </FilterPopover>
  )
}
